<template>
    <div>
        <b-navbar toggleable="lg" type="dark" id="menu">

            <b-navbar-brand to="/">
                <img class="icon_logo" src="/static/images/sport.png" alt=""> ИС ЕКП
            </b-navbar-brand>
            <b-navbar-brand>
                <label style="font-size: 0.7em; color: white">
                    Вы вошли как: <b><u>{{loginObj.username}}</u></b>
                </label>
            </b-navbar-brand>

            <b-navbar-toggle v-b-toggle.sidebar-backdrop target="nav-collapse" class="navbart">
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav toggleable="sm" style="color: aliceblue" class="ml-auto">
                    <div v-for="item in menu" :key="item.index">
                    <b-nav-item v-if="!item.children" :to="item.url" :target="item.newBlanc ? '_blank' : ''">
                        <b-icon :icon="item.icon"></b-icon> <b>{{item.title}}</b>
                    </b-nav-item>
                    <b-nav-item-dropdown :text="item.title" right v-else class="myclass">
                        <template #button-content>
                            <b-icon :icon="item.icon"></b-icon> <b>{{item.title}}</b>
                        </template>
                            <b-dropdown-item variant="light"
                                             active-class="text-light"
                                             v-for="child in item.children" :key="child.index" :to="child.url">
                                <b-icon :icon="child.icon"/>
                                <b> {{ child.title }}</b>
                            </b-dropdown-item>

                    </b-nav-item-dropdown>
                    </div>
                    <b-nav-item to="/logout">
                        <b-icon icon="box-arrow-right"></b-icon> <b>Выйти</b>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>

        </b-navbar>
        <b-sidebar
                id="sidebar-backdrop"
                title="ИС ЕКП"
                backdrop-variant="secondary"
                backdrop
                bg-variant="dark"
                text-variant="light"
                shadow
        >
            <b-container fluid>
                <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1" v-for="item in menu" :key="item.key" bg-variant="secondary">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle="item.key" variant="info" :to="item.url" class="menuitem">
                                <b-icon :icon="item.icon"></b-icon> <b>{{item.title}}</b>
                            </b-button>
                        </b-card-header>
                        <b-collapse :id="item.key" accordion="my-accordion" role="tabpanel" v-if="item.children">
                                <b-list-group>
                                    <b-list-group-item variant="warning" class="text-dark text-center menuitem"
                                                       v-for="child in item.children" :key="child.key" :to="child.url">
                                        <b-icon :icon="child.icon"/> <b>{{ child.title }}</b>
                                    </b-list-group-item>
                                </b-list-group>
                        </b-collapse>
                    </b-card>
                    <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
                        <b-button block variant="danger" to="/logout">
                            <b-icon icon="box-arrow-right"></b-icon> <b>Выйти</b>
                        </b-button>
                    </div>
                </div>
            </b-container>

            <!--<template #footer="{ hide }">-->
                <!--<div class="d-flex bg-dark text-light align-items-center px-3 py-2">-->
                    <!--<b-button block variant="info" to="/logout">-->
                        <!--<b-icon icon="box-arrow-right"></b-icon> <b>Выйти</b>-->
                    <!--</b-button>-->
                <!--</div>-->
            <!--</template>-->

        </b-sidebar>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                collapsed: true,
                loginObj: {username: '', org: ''},
                menu: [
                    {
                        title: 'На главную', icon: 'box', key: 'main', url: '/main', newBlanc: true
                    },
                    {
                        title: 'Справочники', icon: 'box', key: 'spr', children: [
                        {title: 'Пользователи', url: '/userslist', icon: 'person-circle', isAdmin: true},
                        {title: 'Роли', url: '/roles', icon: 'person-bounding-box', isAdmin: true},
                        {title: 'Ответственные', url: '/people', icon: 'person-square'},
                        {title: 'Раздел ЕКП', url: '/sportkind', icon: 'tags'},
                        {title: 'Спортивные федерации', url: '/federslist', icon: 'star'},
                        {title: 'Объекты спорта', url: '/location', icon: 'building'},
                        {title: 'Статус мероприятий', url: '/eventkind', icon: 'list-stars'},
                        {title: 'Рабочий фильтр', url: '/eventstate', icon: 'filter'},
                        {title: 'Организации', url: '/organization', icon: 'building'},
                        {title: 'Характеристики ЕКП', url: '/workkind', icon: 'card-checklist'},
                        {   url: '/rank',
                            title: 'Справочник званий',
                            icon: 'bookmark-star-fill',
                        },
                        {
                            url: '/school',
                            title: 'Справочник школ',
                            icon: 'building',
                        },
                    ]
                    },
                    {
                        title: 'ЕКП', icon: 'calendar3', key: 'ekp',
                        children: [
                          {title: 'Календарь', url: 'data', icon: 'calendar2-date'},
                          {title: 'Лимиты', url: 'Limit', icon: 'bar-chart-line'},
                          {title: 'Заявки в ЕКП', url: 'requestEvent', icon: 'card-checklist'},
//                            {title: 'Заявки на участие', url: 'requestSport', icon: 'card-checklist'},
                          {title: 'Спортсмены', url: 'peopleSport', icon: 'card-checklist'},
                          {title: 'Документы', url: 'document', icon: 'calendar2-date'},
                          {title: 'Перенос сумм', url: 'moveSumma', icon: 'arrow-left-right'},
                    ]
                    },
                    {
                        title: 'КОСГУ(Статьи)', icon: 'diagram3', key: 'cost',
                        children: [
                            {title: 'Статьи', url: 'analyticCode', icon: 'diagram2'},
                            {title: 'Нормы расходов', url: 'norm', icon: 'bar-chart-line'}
                        ]
                    },
                    {
                      title: 'Отчеты', icon: 'layout-text-window', key: 'report',
                      children: [
                        {title: 'Сводный отчет', url: 'reportkind', icon: 'layout-text-window-reverse'},
                        {title: 'Общий отчет ЕКП', url: 'reportekp', icon: 'layout-text-window-reverse'},
                        {title: 'Итоговый отчет по расходам', url: 'reportcost', icon: 'layout-text-window-reverse'},
                        {
                          title: 'Отчет по количеству мериприятий и заявок',
                          url: 'reportrequest',
                          icon: 'layout-text-window-reverse'
                        },
                        {title: 'Журналирование', url: 'logging', icon: 'layout-text-window-reverse'}
                      ]
                    },
                    {
                        title: 'Настройки', icon: 'gear', key: 'sett',
                        children: [
                            {title: 'Смета', url: 'settingsmeta', icon: 'gear'},
                            {title: 'Цвет строк таблицы ЕКП', url: 'settingekp', icon: 'gear-wide'}
                        ]
                    },
                ]
            }
        },
        computed: {
            isLoggedIn: function () {
                return this.$store.getters.isLoggedIn
            },
        },
        created() {
            this.getUserInfo()
        },
        methods: {
            getUserInfo() {
                var login = localStorage.getItem("user")
                var vm = this
                axios.get('/users/status/'+login)
                    .then((response) => {
                        vm.loginObj.username = response.data
                    })
                    .catch((error) => console.log(error.message))
            }
        }
    }
</script>

<style>
    #menu {
        border-radius: 0 0 10px 10px;;
        background-color: #202450;
    }
    .menuitem {
        font-size: 0.8em;
    }
    .icon_logo {
        max-width: 20px;
        margin: 0 auto;
    }
    #navbar_v {
        font-size: 0.8em;
    }
    .activeitem {
        color: black;
    }
    .myclass .dropdown-menu {
        margin-top: 15px;
        background-color: #202450;
    }
    .myclass a:hover {
        background: #4c6c78; /* Цвет фона под ссылкой */
    }
    .myclass a:active {
        background: #4c6c78; /* Цвет фона под ссылкой */
    }
    /*.dropdown-menu {*/
        /*display: block;*/
        /*transition: max-height 300ms ease-in-out;*/
        /*max-height: 500px!important;*/
    /*}*/
    /*.navbar-nav .dropdown-menu {*/
        /*max-height: 0;*/
        /*overflow: hidden;*/
        /*transition: max-height 0.5s;*/
    /*}*/
    /*.navbar-nav .dropdown-menu.show {*/
        /*max-height: 500px;*/
    /*}*/
</style>