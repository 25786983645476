import axios from "axios";

export default {
    isMobileWidth() {
        return 992;
    },
    getFormattedDate(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return year + '-' + month + '-' + day;
    },
    getFormattedDateRU(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return day + '.' + month + '.' + year;
    },
    stringToDate(dateStr) {
        console.log(dateStr);
        const [year, month, day] = dateStr.split("-")
        return new Date(year, month - 1, day)
    },
    getFullUrlImage(file_name) {
        if (file_name && file_name !== '') {
            var href = window.location.href;
            var host = window.location.host;
            var idx = href.indexOf(host);
            href = href.substr(0, idx + host.length)
            return href + '/api/site/file/news?filename=' + file_name;
        } else
            return '/static/images/photoEmpty.png'

    },
    getFullUrlDocument(file_name) {
        if (file_name && file_name !== '') {
            var href = window.location.href;
            var host = window.location.host;
            var idx = href.indexOf(host);
            href = href.substr(0, idx + host.length)
            return href + '/api/report/download/doc/documents?name=' + file_name;
        } else
            return null
    },
    getExt(filename) {
        var r = /.+\.(.+)$/.exec(filename);
        return r ? r[1] : null;
    },
    getStrEmptyOrNull(str) {
        if (str)
            return str
        else
            return ''
    },
    getFileLink(pathStr, keyFile, fileExtention) {
        let fileName = '';
        if (keyFile) {
            fileName = keyFile;
            if (keyFile && keyFile.indexOf('.') < 0) {
                fileName = keyFile + '.' + fileExtention;
            }
        }
        return '/report/download/doc/' + pathStr + '?name=' + encodeURIComponent(fileName);
    },
    downloadFile(pathStr, rec, fileExtention) {
        let vm = this;
        this.modalLoadOpened = true;
        let exp = fileExtention
        if (rec.keyFile && rec.keyFile.lastIndexOf(".") >= 0)
            exp = rec.keyFile.substring(rec.keyFile.lastIndexOf(".") + 1)
        let linkFile = this.getFileLink(pathStr, rec.keyFile, exp)
        axios.get(linkFile,
            {
                responseType: 'blob',
            }
        ).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', rec.nameDoc + '.' + exp);
            document.body.appendChild(link);
            link.click();
        })
            .catch(() => alert('Ошибка загрузки файла!'))
            .finally(() => vm.modalLoadOpened = false);
    },
    validateNumber(value) {
        let keyCode = value.keyCode;
        if (keyCode < 48 || keyCode > 57) {
            value.preventDefault();
        }
    }
}