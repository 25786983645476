<template>
    <div>
        <b-container fluid>
            <b-row>
                <b-col sm="2" v-if="viewLogo">
                    <sidebar-list class="sidebarlist" @openLoginForm="openLoginForm"></sidebar-list>
                </b-col>
                <b-col lg="8" md="12" sm="12">
                    <div class="flex">
                        <div class="img__container">
                            <b-img src="/static/images/mount.png" fluid-grow alt="Responsive image" id="picture"></b-img>
                            <div class="img__description row">
                                <b-col md="12" v-if="viewLogo">
                                    <b-img class="icon_logo2 d-inline" src="/static/images/logoBuryatia.png" alt="Лого2"></b-img>
                                    <label class="ml-2 text-dark font-weight-bold">Министерство спорта и молодежной
                                        политики Республики Бурятия</label>
                                </b-col>
                                <b-col md="12" v-else>
                                    <label class="ml-2 text-dark" style="font-size: 0.7em">Министерство спорта и молодежной
                                        политики Республики Бурятия</label>
                                </b-col>
                            </div>
                            <div class="img__description0 text-right" v-if="!viewLogo">
                                <b-button size="sm" variant="info" v-b-toggle.sidebar-backdrop class="mr-2">
                                    <b-icon icon="list"/>
                                </b-button>
                            </div>
                        </div>

                        <div class="h100">
                            <router-view/>
                        </div>

                        <div class="mini-footer">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="copyright-text">
                                            <p>© 2021
                                                <a href="#">ДСС РБ</a>. Все права защищены. Created by
                                                <a href="#">vladdd2006</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>

        </b-container>

        <b-sidebar
                id="sidebar-backdrop"
                title="Меню"
                backdrop-variant="dark"
                backdrop
                shadow
                v-model="viewSidebar"
                v-if="!viewLogo"
        >
            <sidebar-list class="sidebarlist" @openLoginForm="openLoginForm"></sidebar-list>

            <!--<template #footer="{ hide }">-->
                <!--<div class="d-flex align-items-center px-3 py-2">-->
                    <!--<b-button block v-if="isLoggedIn" size="sm" variant="danger" to="/logout">Выйти</b-button>-->
                <!--</div>-->
            <!--</template>-->

        </b-sidebar>

        <modal name="loginModal" draggable=".window-header" height="auto" width="400" :adaptive="true">
            <div class="window-header bg-info text-light">
                <b-row>
                    <b-col lg="12" class="text-light text-center my-2">
                        Авторизация
                    </b-col>
                </b-row>
            </div>
            <b-container fluid class="my-2">
                <login-form>
                </login-form>
            </b-container>
        </modal>

    </div>
</template>

<script>
    import LoginForm from '../components/LoginForm'
    import SidebarList from '../components/SidebarList'
    import global from '../utils/global'

    export default {
        components: {LoginForm, SidebarList},
        data() {
            return {
                items: [],
                modalLoginOpened: false,
                viewSidebar: false,
                viewLogo: false
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
            var vm = this;
            this.$http.interceptors.response.use(undefined, function (err) {
                return new Promise(function (resolve, reject) {
                    if ((!err.response || err.response.status === 401) && err.config && !err.config.isRetryRequest) {
                        vm.logout()
                    }
                    throw err
                })
            })
        },
        methods: {
            onResize() {
                // Small (sm) <=540px
                if (document.documentElement.clientWidth >= global.isMobileWidth()) {
                    this.viewLogo = true
                } else {
                    this.viewLogo = false
                }
            },
            openLoginForm() {
                this.viewSidebar = false
                this.$modal.show('loginModal')
            },
            logout: function () {
                // console.log("logout")
                this.$store.dispatch('logout');
                //     .then(() => {
                //         this.$router.push('/main')
                //     }).catch((error) => {
                // })
            }
        },
    }
</script>

<style>
    .img__container {
        position: relative;
        /*margin: auto auto auto auto;*/
    }

    .img__container > img {
        display: inline-block;
        max-width: 100%;
        height: auto;
        border-radius: 4px;
        box-shadow: 0 0 6px #9E9E9E;
        border: 4px solid #fff;
    }

    .img__description0 {
        position: absolute;
        left: 0;
        right: 0;
        top: 10px;
    }

    .img__description {
        position: absolute;
        left: 0;
        right: 0;
        top: 5px;
        padding-left: 5px;
        color: white;
        /*background-color: rgba(255, 255, 255, 0.8);*/
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .img__description2 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px;
    }

    .img__header {
        font-weight: bold;
        font-size: 1.25em;
        color: #25296d;
    }

    .icon_logo2 {
        max-width: 60px;
        margin: 0 0;
    }

    .header-h2 h1 {
        border-bottom: 2px solid #293f50; /* Параметры линии под текстом */
        font-weight: normal; /* Убираем жирное начертание */
        padding-bottom: 5px; /* Расстояние от текста до линии */
        /*display: inline-block;*/
        /*background: #fff;*/
        margin-bottom: 0;
        font-size: 1rem;
        text-transform: uppercase;
        padding-left: 1rem;
        padding-right: 1rem;
        color: #25296d;
        font-weight: bold;
    }

    .mini-footer {
        background: #192027;
        text-align: center;
        padding: 10px 0;
    }

    .mini-footer p {
        margin: 0;
        line-height: 26px;
        /*font-size: 15px;*/
        color: #999;
        font-size: 0.7em;
    }

    .mini-footer p a {
        color: #5867dd;
    }

    .mini-footer p a:hover {
        color: #34bfa3
    }

    .flex {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    body {
        margin: 0;
        padding: 0
    }

    .h100 {
        /*background: yellow;*/
        flex-grow: 2
    }
    #picture {
        opacity: 0.7;
        max-height: 100px;
    }
    .sidebarlist {
        height:100%;
    }
</style>