<template>
    <div id="login">
        <!--<b-container fluid>-->

        <!--<b-col lg="4" md="6" sm="8" offset-lg="4" offset-md="3" offset-sm="2" style="padding-top: 5%">-->
        <!--<b-card header="Войти" header-bg-variant="info"-->
        <!--class="text-center" footer-tag="footer" border-variant="primary">-->
        <!--<template #header>-->
        <!--<b-row>-->
        <!--<b-col sm="2">-->
        <!--<img class="icon_logo2" src="/static/images/sport.png" alt="">-->
        <!--</b-col>-->
        <!--<b-col sm="8" class="text-light">-->
        <!--<h5>Министерство спорта и молодежной политики Республики Бурятия</h5>-->
        <!--</b-col>-->
        <!--</b-row>-->
        <!--</template>-->
        <b-form @submit="submitForm" onSubmit="return false;">
            <b-input-group size="md" class="inputtext mt-1">
                <b-input-group-text>
                    <b-icon icon="person-circle"></b-icon>
                </b-input-group-text>
                <b-form-input required type="text" @input="validForm=true"
                              v-model="form.username"
                              placeholder="Логин"></b-form-input>
            </b-input-group>
            <b-input-group size="md" class="inputtext mt-1">
                <b-input-group-text>
                    <b-icon-lock-fill></b-icon-lock-fill>
                </b-input-group-text>
                <b-form-input required type="password" @input="validForm=true"
                              v-model="form.password"
                              placeholder="Пароль">
                </b-form-input>
            </b-input-group>
            <b-form-invalid-feedback :state="validForm" class="mt-1 mb-2">Ошибка ввода логин/пароль</b-form-invalid-feedback>
            <b-button block size="sm" variant="primary" type="submit" class="mt-1">Войти</b-button>
            <div class="text-center">
                <em>При входе вы подтверждаете согласие с  <b-link to="/privacy">политикой о данных пользователей</b-link>.</em>
            </div>

        </b-form>

        <!--<template v-slot:footer>-->
        <!--<em>При входе вы подтверждаете согласие с  <b-link to="/privacy">политикой о данных пользователей</b-link>.</em>-->
        <!--</template>-->
        <!--</b-card>-->
        <!--</b-col>-->
        <!--</b-container>-->
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'LoginPage',
        data() {
            return {
                form: {username: '', password: ''},
                validForm: true,
            }
        },
        methods: {
            submitForm() {
                let username = this.form.username
                let password = this.form.password

                axios.post('/auth/login', {username, password}).then((response) => {
                    const token = response.data.token
                    const user = response.data.user
                    const roles = response.data.roles;
                    localStorage.setItem('token', token)
                    localStorage.setItem('user', user)
                    localStorage.setItem('roles', roles)
                    //axios.defaults.headers.common['Authorization'] = 'Bearer_' + token
                    //commit('auth_success', token, user, roles)
                    //resolve(response)
                    this.$store.dispatch('login', { user, token, roles })
                    this.$router.replace('/EKP').catch(error => {
                        console.info(error.message)
                    })
                }).catch((error) => {
                    this.$store.dispatch('logout')
                    //commit('auth_error')
                    this.validForm = false
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    localStorage.removeItem('roles')
                })

            }
        }
    }
</script>
<style>

</style>